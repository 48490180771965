import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import product_back from "../images/product_back.png";
import Roobot2 from "../images/RooBot2.png";
import keyboard from "../images/Keyboard.png";
import {Helmet} from "react-helmet";
import SEO from "./seo";

export default function Services() {
    return <div>
        <SEO title="Deep expertise in Saas data management"
             description="We can construct custom metrics from Saas application data."
        ></SEO>
        <div className="container-fluid p-0">
            <div className="row m-0">
                <div className="col-md-12 p-0">
                    <div style={{ backgroundImage: `url(${product_back})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPositionX: 'center' }}>
                        <div style={{ minHeight: 150 }}>
                            <Header/>
                        </div>
                        <div style={{ height: 120 }}>
                        </div>
                        <div>
                            <div className="home-hero-div">
                                <div className="home-hero-titlelight">Services</div>
                            </div>
                        </div>
                        <div style={{ height: 240 }}></div>
                    </div>
                </div>
            </div>
        </div>
        <div style={{ height: 50 }}></div>
        <div className="container-fluid">
            <div className="row m-5">
                <div className="col-md-6">
                    <img src={keyboard} align="right"  className="flow-image"/>
                </div>
                <div className="col-md-6 order-md-first">
                    <div className="vertical-center">
                    <div className="textblock-left">
                        <div className="title">
                            Professional Services
                        </div>
                        <div className="body">
                            
                            From Quickbooks to Salesforce to Jira, we understand how to extract, merge, and analyze data
                            from deep within your Saas infrastructure. Using this data, we can go beyond the simple analytics
                            offered by most Saas providers to construct metrics that are tailored to your operation.
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div className="row m-0">
                <div className="col-md-12">
                    <div style={{ height: 200 }}></div>
                </div>
            </div>
        </div>

        <div style={{ height: 100 }}></div>

        <div className="roobot-2">
            <img src={Roobot2}/>
        </div>
        <br clear="all"/>

        <Footer />

    </div>
}